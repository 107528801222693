import React from 'react'
import styled from 'styled-components/macro'

import Layout from '../components/layout'
import InformedConsent from '../components/InformedConsent'

const informedConsent = () => (
  <Layout hideSignUpButton={true}>
    <InformedConsent />
  </Layout>
)


export default informedConsent
