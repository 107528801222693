import React from 'react'
import styled from 'styled-components'

export const InformedConsent = () => {
  return (
    <Wrapper>
    <h2>
      Informed Consent
    </h2>
    <h4>
    This is a legal and binding document between you and Truepill on behalf of itself, its subsidiaries, and affiliated professional entities (collectively, “We,” “Our”, or “Us”). Read it carefully before clicking “I accept”. 
    </h4>
    By clicking “I accept”, you hereby consent to receive clinical services from health care providers contracted with Truepill or Truepill-affiliated professional entities (in either case, “Truepill-affiliated Providers”) who are located at sites remote from you to provide consultative services to you. The receipt of clinical services from a Truepill-affiliated Provider via humana.truepill.com (the “Services”) is a type of “telemedicine” or “telehealth” service. 
    <h3>
    Description of Services
    </h3>
    Truepill-affiliated Providers may include primary care practitioners, specialists, nurse practitioners, physician’s assistants and/or subspecialists.  In some cases, telemedicine visits may not be the most appropriate way for you to seek medical care and treatment.  For example, certain medical conditions may require an in-person procedure, more urgent attention, or a healthcare provider other than your healthcare provider using the Services.
    <ul>
      <li>We may ask you a series of initial questions to help you determine whether a telemedicine visit is appropriate for you.  Based on your responses to these questions, we may determine that a telemedicine visit may not be appropriate for the particular issue for which you are seeking a telemedicine visit or for other reasons related to your health status.  In such a case: (i) you will receive an alert notifying you that you will be unable to use the Services for the particular issue you submitted; (ii) your request for a telemedicine visit will not be submitted to your Truepill-affiliated Provider; (iii) your Truepill-affiliated Provider will not receive any of the information that you submitted; and (iv) you will need to seek any needed care in another way.  Your Truepill-affiliated Provider may, following submission of a telemedicine visit request, determine that your diagnosis or treatment requires an in-person office visit or is otherwise not appropriately addressed through use of the Services.  In such a case, your Truepill-affiliated Provider may notify you that you will be unable to use the Services for the particular issue you submitted.</li>
      <li>Truepill will have no responsibility or liability for your Truepill-affiliated Provider’s delay or failure to respond to a telemedicine visit request, to notify you that your telemedicine visit cannot be completed, or to provide you with next steps or follow-up information, or for any care, medical advice or treatment provided by your Provider.</li>
    </ul>
    <h3>
      Benefits and Risks
    </h3>
    Your use of the Services may have the following possible benefits:
    <ul>
      <li>
Making it easier and more efficient for you to seek medical care and treatment for the conditions treated by the applicable healthcare provider;
      </li>
      <li>
Allowing you to seek medical care and treatment by your Truepill-affiliated Provider at times that are convenient for you; and
      </li>
      <li>
Enabling you to communicate with your Truepill-affiliated Provider without the necessity of an in-office appointment.
      </li>
    </ul>
    As with any medical procedure, there are potential risks associated with the use of telemedicine or telehealth services, which may include, without limitation, the following:
    <ul>
      <li>
The information transmitted to your Truepill-affiliated Provider may not be sufficient (e.g., poor resolution of images) to allow your Truepill-affiliated Provider to make an appropriate medical decision;
      </li>
      <li>
Your Truepill-affiliated Provider’s inability to conduct certain tests or assess vital signs in-person may in some cases prevent the provider from providing a diagnosis or treatment or from identifying the need for emergency medical care or treatment for you;
      </li>
      <li>
Your Truepill-affiliated Provider may not be able to provide medical treatment for your particular condition and you may be required to seek alternative healthcare or emergency care services;
      </li>
      <li>
 Delays in medical evaluation/treatment or a failure to obtain needed treatment could occur due to unavailability of your Truepill-affiliated Provider, deficiencies or failures of the technology or electronic equipment used, a transmission delay or failure, issues with the internet or other communications means, or for other reasons;
 </li>
 <li>
 The electronic systems, public networks, or security protocols or safeguards used in the Services could fail, causing a breach of privacy of your medical or other information;
 </li>
 <li>
 Your Truepill-affiliated Provider’s diagnosis and treatment options, especially pertaining to certain prescriptions, may be limited; 
 </li>
 <li>
 Lack of access to your medical records or ability to perform an in-person examination, which could result in negative health outcomes (e.g., adverse drug interactions, allergic reactions).
 </li>
    </ul>
Please note that your telehealth visit with Truepill-affiliated Provider is not covered by insurance. You agree that you are: (i) fully responsible for payment for all fees billed to you related to telemedicine visits; and (ii) responsible for asking any questions that you may have about and fully understanding your financial responsibility before using the Services.  Please reach out to humana@truepill.com
or call (855) 645-8114 for any questions regarding fees and billing for telemedicine visits.
    <h3>
      By clicking “I accept”, you also represent and warrant the following:
    </h3>
    <ul>
    <li>
Your Truepill-affiliated Provider has discussed the use of telemedicine services with you, including the benefits and risks of such use and the alternatives to the use of the Services, and you have provided consent to your Truepill-affiliated Provider for the use of the Services.
</li>
<li>
You understand that the use of the Services involves electronic communication of your personal medical information to your Truepill-affiliated Providers who may be located in other areas, including outside of the state in which you reside, and that the electronic systems, public networks, or security protocols or safeguards used in the Services could fail, causing a breach of privacy of your medical or other information.
</li>
<li>
You understand that it is your duty to provide your Truepill-affiliated Provider truthful, accurate, and complete information, including all relevant information regarding care that you may have received or may be receiving from other healthcare providers or outside of the Services.
</li>
<li>
You understand that your Truepill-affiliated Provider may determine that your condition is not suitable for diagnosis or treatment using the Services, or may fail to respond promptly or ever to your request for a telemedicine service, and that you may need to seek medical care and treatment from your Truepill-affiliated Provider, a specialist, or other healthcare provider outside of the Services.
</li>
<li>
You understand the risks and benefits of the Services and its use in the medical care and treatment provided to you by your Truepill-affiliated Provider.
</li>
<li>
No potential benefits from the use of the Services, care provided via the Services, or specific results can be guaranteed.  Your condition may not be cured or improved, and in some cases, may get worse.
</li>
<li>
You understand that you can withhold or withdraw this consent at any time by emailing humana@truepill.com  with such instruction. Otherwise, this consent will be considered renewed upon each new telemedicine consultation with Truepill-affiliated Providers.
</li>
<li>
You agree and authorize your Truepill-affiliated Provider to share information regarding the telemedicine exam with other individuals for treatment, payment and health care operations purposes.
</li>
  </ul>
<h3>
Acknowledgement
</h3>

<h4>
By clicking “I accept”, you (a) further certify that you are the patient, or that you are duly authorized by the patient as the patient’s representative or legal guardian, (b) acknowledge and accept the risks identified above and the terms associated with the receipt of clinical services via the Services, and (c) give your informed consent to receive clinical services under the terms described herein.  
</h4>
  </Wrapper>
  )
}

const Wrapper = styled.div`
  grid-column: contentStart/contentEnd;
`

export default InformedConsent
